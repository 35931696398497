$sidebar-bg: #58A3D3;
$sidebar-hover-bg: #4789b3 !important;

body.theme-color-MEDIUM_BLUE {
    .c-sidebar {
        background: $sidebar-bg;
    }

    .c-sidebar-nav-item:hover > .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
        background: $sidebar-hover-bg;
    }
}
