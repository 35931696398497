#footer-settings {
  position: fixed;
  bottom: 0;
  right: 0;
}

.theme-contabilone .cone-containers-general {
  .c-footer {
    background: transparent;
    border: 0 none;
    justify-content: center;

    .nav > li > a {
      color: #3c4b64;
      &:hover {
        color: #d38d19;
      }
    }

    height: 80px;
  }
}