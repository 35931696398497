.pagination {
  height: 40px;

  .form-group.m-0 {
    &:not(:first-child) .form-control-text {
      border-top-left-radius: 0!important;
      border-bottom-left-radius: 0!important;
    }
    & input {
      max-width: 80px;
      width: 100%;
      border-top-right-radius: 0!important;
      border-bottom-right-radius: 0!important;

      &:focus {
        box-shadow: unset!important;
        border-color: #919191;
      }
  
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  
      &[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
      }

    }
  }

  .pagination-dropdown.show[data-popper-placement="top-start"] {
    transform: translate(-35px, -32px) !important;
  }
}

.pagination.pagination-sm {
  & > li.page-item {
    &.active > a.page-link {
      color:#474747 !important;
      background-color: #f4f4f4 !important;
      border-color: #8e8e8e !important;
    }
    & > a.page-link:not(.disabled) {
      color:#474747 !important;
      &:focus {
        box-shadow: none!important;
      }
    }
  }
}
