a {
    color: #303c54;

    &:hover {
        color: #395385;
    }
}
.table-invoice{
    vertical-align: top !important;
}
.table {
    &.text-truncate {
        th, td {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
        }
    }

    tr.strong {
        th, td {
            font-weight: bold;
        }
    }

    &.table-sm {
        th, td {
            height: 41px;
        }
    }
}

.form-control {
    &.search-header {
        &:focus {
            box-shadow: none;
        }
    }

    &.form-control-text {
        height: auto;
    }
}

.c-header {
    .dropdown-item.active {
        background: transparent !important;
    }
}

/* Customize lateral menu hover color */
.c-sidebar, .c-sidebar.c-sidebar-minimized {
    .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
    .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle {
        background: #303c54;
    }
}

/* Customize all breadcrumb background blue color */
.dropdown-item.active, .dropdown-item:active {
    background: var(--light);
    color: var(--dark);
}

.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed.c-sidebar-unfoldable:hover .c-sidebar-nav-title,
.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed:not(.c-sidebar-unfoldable) .c-sidebar-nav-title {
    min-height: 36px;
}

.c-dark-theme {
    a {
        color: var(---light);
        &:hover {
            color: var(--light-dark-theme);
        }
    }

    .btn {
        color: var(---light);
    }

    .dropdown-item.active, .dropdown-item:active {
        background: transparent;
        color: white;
    }
}

.toaster {
    .toast {
        .toast-body {
            li {
                word-break: break-all;
            }
        }
    }
}

.cone {
  &-access-key {
      &-bg {
      position: relative;
      dl {
          position: relative;
          &.cone-access-key-dash {
          &:after {
              content: '-';
              font-weight: bold;
              color: #000;
              position: absolute;
              right: -5px;
              top: 15px;
              width: 20px;
          }
          }
      }
      &:before {
          content: '';
          background-color: rgba(225, 225, 225, 0.5);
          border-radius: 13px;
          top: 15px;
          height: 26px;
          left: -7px;
          position: absolute;
          right: 25px;
          z-index: 0;
      }
    }
  }
}

.c-sidebar-nav-dropdown-toggle::after {
    transform: rotate(-90deg) !important;
}
.c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle::after {
    transform: rotate(90deg) !important;
}

.navLink-disabled {
    pointer-events: none !important;
    background: transparent !important;
}