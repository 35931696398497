.status-header-badge {
  display: block !important;
  align-items: center !important;
  justify-content: center !important;
  height: 1.5rem !important;
  font-size: 1rem !important;
  font-weight: normal !important;
  text-align: center !important;
  padding: 0.5rem !important;
  line-height: 0.2rem;
}