@keyframes slideleft {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}

.modal-stack {
  position: absolute;
  z-index: 1070;

  &.modal-stack-close {
    .modal-stack-content {
      right: -100vw;
    }
    .modal-stack-background {
      opacity: 0;
    }
  }
  &-container {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
  }
  &-background {
    background-color: #000015;
    height: 100vh;
    left: 0;
    opacity: .32;
    // transition: all 0.3s ease-in;
    position: fixed;
    top: 0;
    width: 100vw;
  }
  &-content {
    background-color: #ebedef;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    // transition: all 0.3s ease-in;
    width: 100vw;
    overflow: auto;
    // animation-duration: 0.3s;
    // animation-name: slideleft;
    // animation-timing-function: ease-out;
  }
  &.last &-content {
    width: 80vw;
  }
  &-header {
    background-color: #fff;
    min-height: 56px;
  }
  &-body {
    padding-top: 2rem;
  }
}

.c-sidebar-minimized ~ .c-wrapper {
  .modal-stack.last {
    .modal-stack-content {
      width: 90vw;
    }
  }
}
