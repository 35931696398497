.debug {
  &, div {
    border: 1px solid red;
  }
}

.debug-1 {
  & > div {
    border: 1px solid red;
  }
}
