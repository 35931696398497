.header-select {
    z-index: 10;
  }

.autorefresh-auto {
  height: 37px;
  & > div {
    min-width: 80px;
  }
}

.accounting-entries-header-action {
  display: flex;
    & input {
      width: 280px !important;
    }
    & .input-group-prepend, & .input-group-append { 
      height: 34px!important;
    }
}

.card-header-custom {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}