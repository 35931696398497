.table {
  &.table-hover {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  .cell-fit {
    width: 1%;
  }
}

.simulation-table {
  display: flex;
  margin: 1.5rem;

  & .row-space {
    height: 20px;

    & > .group-title {
      font-weight: bold;
    }
  }

  & > div {
    max-width: 200px;
    width: 100%;

    &:first-child {
      max-width: 250px;
    }

    &:not(:nth-child(1)) > .simulation-item {
      justify-content: center;
    }
    
    & > .simulation-item {
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #f6f6f6;
      padding-top: 5px;
      position: relative;
      // box-shadow: 0px 8px 9px 0px #bab9b9;
    }

    & .simulation-title {
      height: 60px;
      background-color: #102542;
      box-shadow: unset;

      & > h5 {
        color: #fff;
        font-weight: bold;
        font-size: 0.875rem;
      }

      &.-empty {
        background-color: transparent;
        box-shadow: unset;
      }
    }
  }
}

.-visible {
  overflow: visible !important;
}

.table-break-spaces {
  white-space: break-spaces;
}

.no-hover:hover {
  background-color: transparent!important;
}

.rules-wrapper {
  padding-right: 0!important;
}

@media only screen and (max-width: 820px) {
  .invite-form-body tbody td .form-group > div:first-of-type {
    height: 40px;
    & > div:first-of-type {
      height: 100%;
    }
  }
}

.report-table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  font-weight: bold;
  column-gap: 3rem;
  margin-left: 2rem;
  margin-right: -0.6rem;

  div {
    margin-top: 1rem;
  }
}

.table-fields {
  thead tr:first-child th{
    padding: 4.8px;
  }
}

.subgroup-fields {
  thead{
    display: table-header-group !important;
    th {
      font-weight: normal !important;
      border-bottom: 1px solid #d8dbe0 !important;
    }
  }
}

.subgroup-border td {
  border: none !important;
  border-bottom: 1px solid #d8dbe0 !important;
}

.subgroup-border tr:last-child td {
  border-bottom: none !important;
}

.no-table-header {
  tbody {
    tr:first-child td {
      border-top: none !important;
    }
  }

  thead {
    display: none !important;
  }
  margin-top: -0.5rem !important;
}