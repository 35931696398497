.steps-bar {
  position: relative;
  height: 33px;

  &:before {
    background: #ddd;
    content: '';
    height: 6px;
    left: 15px;
    position: absolute;
    top: 12px;
    width: calc(100% - 30px);
    z-index: 10;
  }

  &-complete-line {
    background: #4f92ff;
    content: '';
    height: 6px;
    left: 15px;
    position: absolute;
    top: 12px;
    z-index: 15;
  }

  ul {
    position: relative;
    margin: 0;
    padding: 0;
    z-index: 20;

    li {
      list-style: none;
      padding: 0;
      transition: all 0.5s ease;

      button {
        border-radius: 30px;
        transition: all 0.5s ease;
        position: relative;
        height: 30px;
        margin: 0px 0 0 0;
        padding: 0;
        width: 30px;

        &:hover {
          background-color: #ddd;
        }

        &:before {
          transition: all 0.5s ease;
          background-color: #bbbbbb;
          border-radius: 30px;
          content: '';
          height: 12px;
          position: absolute;
          width: 12px;
          top: 8px;
          left: 8px;
        }
      }

      &.complete {
        button {
          &:hover {
            background-color: #4f92ff;
          }

          &:before {
            background-color: #4f92ff;
          }
        }
      }

      &.error, &.error.active {
        button {
          &:hover {
            background-color: #e55353;
          }

          &:before {
            background-color: #e55353;
          }
        }

        &.active {
          button {
            background-color: #e55353;
          }
        }
      }

      &.active {
        margin: 0;

        button {
          background-color: #ddd;
          margin: 0;

          &:before {
            background-color: #4f92ff;
          }
        }
      }
    }
  }
}

.steps {
  counter-reset: my-awesome-counter;

  &-item {
    counter-increment: my-awesome-counter;

    &-number {
      &:before {
        content: counter(my-awesome-counter)
      }
    }
  }
}
