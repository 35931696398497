.cone-containers-general {
  background: transparent no-repeat center center;
  background-size: cover;
  .cone-brand-logo {
    img {
      max-width: 150px;
    }
  }

  .display-4 {
    font-size: 1.6rem;

    @media only screen and (max-width: 576px) {
      font-size: 1.3rem;
    }
  }
}

.toast-body {
  word-break: keep-all;
}

.taxes-nav-tabs {
  display: flex !important;
  justify-content: space-between !important;
  padding: 0;
  margin: 0;
  .t-header-nav-item {
    text-align: center !important;
  }
  .t-nav-link {
    width: 100% !important;
  }
}
