.no-padding-top {
  padding-top: 0 !important;
}

.no-border {
  border: none !important;
}

.no-mouse-event {
  pointer-events: auto !important;
  cursor: default !important;
}

.default-mouse {
  pointer-events: default !important;
  cursor: default !important;
}
