.timeline {
  ul {
    list-style: none;

    li {
      border-left: 2px solid #686062;
      padding: 0 0 10px 25px;
      position: relative;

      &:before {
        background-color: #fff;
        border-radius: 100%;
        border: 2px solid #686062;
        content: '';
        height: 13px;
        left: -7.5px;
        position: absolute;
        width: 13px;
      }

      &.timeline-cropped {
        &:before {
          background-color: #686062;
          border: 0px none;
          height: 8px;
          left: -4.8px;
          width: 8px;
        }

        &:nth-last-child(-n+2) {
          border-left-style: dotted;
        }

        &:last-child {
          font-size: 10px;
          border-left: 2px solid #fff;
        }
      }

      p {
        margin: 0;
      }

      .period {
        left: -42px;
        position: absolute;
        top: -1px;
      }
    }
  }
}
