@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#btn-chat {
  padding: 8px 16px 15px 16px;
  position: fixed;
  right: -35px;
  top: 45vh;
  transform: rotate(-90deg);
  transition: all .3s;
  z-index: 5000;

  .c-icon {
    margin-right: 10px !important;
  }

  &:hover {
    right: -30px;
  }

  &.hide {
    right: -70px;
  }

  &.loading svg {
    animation: spin 1s linear infinite;
  }
}

#btn-chat-2 {
  padding: 8px 16px 15px 16px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 5000;

  &.loading svg {
    animation: spin 1s linear infinite;
  }
}
