$sidebar-bg: #FF0000;
$sidebar-hover-bg: #d60000 !important;

body.theme-color-LIGHT_RED {
    .c-sidebar {
        background: $sidebar-bg;
    }

    .c-sidebar-nav-item:hover > .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
        background: $sidebar-hover-bg;
    }
}
