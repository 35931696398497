.cone-containers-authenticated {
  .c-sidebar-brand-minimized {
    width: 30px;
  }

  .c-sidebar-brand-full {
    max-width: 195px;
    max-height: 40px;
  }

  thead {
    th, td {
      border-bottom: 0 none;
      border-top: 0 none;
    }
  }
}
