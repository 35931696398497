.copy-text-tooltip {
    visibility: hidden;

    position: absolute;
    padding: 3px 0px;
    width: 60px;
    left: -0.8rem;
    top: 2.5rem;

    border-radius: 0.25rem;

    background-color: rgb(25,25,44);
    color: rgb(999,999,999);
    text-align: center;

}
.btn-copy-text {
    padding: 0 0;
    padding-right: 4px;
    padding-left: 4px;
    background-color: #e4e7ea;
}

.btn-copy-text.disabled {
    padding: 0 0;
    padding-right: 4px;
    padding-left: 4px;
    background-color: #e4e7ea;
    color:#929BA8;
    opacity: 1;
}

.btn-copy-text:focus .copy-text-tooltip {
    visibility: visible;
}

.-token-wrapper {
    display: flex;
    & > .form-group {
    width: 100%;
    }
    
    & > button {
    min-width: max-content;
    }
}