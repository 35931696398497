.small-screen-popup {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1070;

  &-background {
    background-color: #fff;
    height: 100vh;
    left: 0;
    opacity: .8;
    position: fixed;
    top: 0;
    width: 100vw;
  }

  &-content {
    position: fixed;
    top: 34%;
    text-align: center;

    img {
      height: 30%;
      width: 30%;
    }

    p {
      margin: 9% 20% 0 20%;
    }
  }
}
