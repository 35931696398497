.form-control.password.is-invalid, .was-validated .form-control.password:invalid {
  background-position: center right calc(1.500em + .4875rem) !important; 
}

.form-control.password.is-valid, .was-validated .form-control.password:valid {
  background-position: center right calc(1.500em + .4875rem) !important; 
}

.icon-style {
  position: absolute;
  cursor: pointer;
  top: 28%;
  right: 3%;
}

.font-error {
  font-size: 11px;
  margin-top: 3px;
}