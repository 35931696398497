.copy-text-tooltip{
    visibility: hidden;

    position: absolute;
    padding: 3px 0px;
    width: 60px;
    right: 2rem;
    top: 3rem;

    border-radius: 0.25rem;

    background-color: rgb(25,25,44);
    color: rgb(999,999,999);
    text-align: center;
}



