$sidebar-bg: #830a48;
$sidebar-hover-bg: #610735 !important;

body.theme-color-PINK {
    .c-sidebar {
        background: $sidebar-bg;
    }
    
    .c-sidebar-nav-item:hover > .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
        background: $sidebar-hover-bg;
    }
}
