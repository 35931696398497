$sidebar-bg: #102542;

body.theme-color-BLUE {
    .c-sidebar {
        background: $sidebar-bg;
    }
    .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
        background: #303c54;
    }
}
